import React, { useEffect } from "react";
import Layout from "../../components/_App/layout";
import SEO from "../../components/_App/seo";
import Navbar from "../../components/_App/NavbarStyleFour";
import Footer from "../../components/_App/Footer";
import PageBanner from "../../components/Common/PageBanner";
import * as Icon from "react-feather";
import { Link } from "gatsby";
import BlogSidebar from "../../components/Blog/BlogSidebar";
import { CodeBlock, vs2015 } from "react-code-blocks";
import Blog from "../../assets/json/blog.json";

function MyCoolCodeBlock({ code, language, showLineNumbers }) {
    return (
        <CodeBlock
            text={code}
            language={language}
            showLineNumbers={showLineNumbers}
            theme={vs2015}
        />
    );
}

const articleData = {
    id: 29,
    title: "Integrazione Continua e Distribuzione Continua (CI/CD):",
    desc: "Strumenti e Metodologie",
    img: "/blog-image/cicd.jpg",
    page: "blog/ci-cd",
    data: "17 Ott 2023",
    author: "Staff",
};

const BlogDetails = () => {
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Layout>
            <SEO
                title={articleData.title}
                meta={[
                    {
                        name: `description`,
                        content:
                            "Integrazione Continua e la Distribuzione Continua sono diventate pratiche fondamentali per garantire la qualità del software e la sua consegna efficiente.",
                    },
                    {
                        property: `og:title`,
                        content: articleData.title + "Appius, software house",
                    },
                ]}
            />

            <Navbar />

            <PageBanner pageTitle={articleData.title} />

            <div className="blog-details-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <img
                                        src={
                                            require("../../assets/images" +
                                                articleData.img).default
                                        }
                                        alt="image"
                                    />
                                </div>

                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <Icon.Clock />{" "}
                                                {articleData.data}
                                            </li>
                                            <li>
                                                <Icon.User />{" "}
                                                <Link to="#">
                                                    {articleData.author}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <p>
                                        Nel moderno sviluppo software non
                                        possiamo più farne a meno.
                                        L'Integrazione Continua (CI) e la
                                        Distribuzione Continua (CD) sono
                                        diventate pratiche fondamentali per
                                        garantire la qualità del software e la
                                        sua consegna efficiente. In questo
                                        articolo, esploreremo CI/CD, i suoi
                                        benefici e alcune delle principali
                                        metodologie e strumenti utilizzati in
                                        questo processo.
                                    </p>

                                    <h3>Cos'è CI/CD?</h3>

                                    <p>
                                        L'Integrazione Continua (CI) è una
                                        pratica che coinvolge l'integrazione
                                        regolare del codice sorgente in un
                                        repository condiviso. Ogni modifica al
                                        codice viene testata automaticamente,
                                        garantendo che le nuove modifiche non
                                        interrompano funzionalità esistenti. La
                                        Distribuzione Continua (CD), d'altra
                                        parte, estende CI per includere la
                                        consegna automatica delle modifiche ai
                                        server di produzione o ad altri
                                        ambienti.
                                    </p>
                                    <h3>Benefici di CI/CD</h3>
                                    <p>
                                        L'implementazione efficace di CI/CD
                                        offre diversi vantaggi, tra cui:
                                    </p>

                                    <ol>
                                        <li>
                                            <h5>Riduzione degli Errori</h5>
                                            <ul>
                                                <li>
                                                    <b>
                                                        Riduzione dei Rischio di
                                                        Errori
                                                    </b>
                                                    : La continua verifica e i
                                                    test automatizzati riducono
                                                    il rischio di errori nella
                                                    produzione. Questo significa
                                                    che le modifiche al software
                                                    vengono controllate
                                                    accuratamente prima di
                                                    essere implementate.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h5>Velocità di Consegna</h5>
                                            <ul>
                                                <li>
                                                    <b>
                                                        Consegna Rapida delle
                                                        Modifiche
                                                    </b>
                                                    : Le modifiche possono
                                                    essere consegnate più
                                                    rapidamente, consentendo un
                                                    rilascio frequente e
                                                    reattivo alle esigenze dei
                                                    clienti o ai cambiamenti del
                                                    mercato.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h5>Miglioramento della Qualità</h5>
                                            <ul>
                                                <li>
                                                    <b>
                                                        Miglioramento Continuo
                                                        della Qualità
                                                    </b>
                                                    : Le modifiche possono
                                                    essere consegnate più
                                                    rapidamente, consentendo un
                                                    rilascio frequente e
                                                    reattivo alle esigenze dei
                                                    clienti o ai cambiamenti del
                                                    mercato.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h5>Reversibilità</h5>
                                            <ul>
                                                <li>
                                                    <b>Facilità di Rollback</b>:
                                                    In caso di problemi con una
                                                    nuova versione, è possibile
                                                    tornare facilmente a una
                                                    versione precedente che
                                                    funzionava correttamente.
                                                    Questo riduce il rischio e
                                                    le interruzioni per gli
                                                    utenti finali.
                                                </li>
                                            </ul>
                                        </li>
                                    </ol>
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                    <br />
                                    <h3>Metodologie CI/CD</h3>
                                    <p>
                                        Ecco alcune delle metodologie comuni
                                        utilizzate in CI/CD:
                                    </p>
                                    <ol>
                                        <li>
                                            <h5>Pipeline di CI/CD</h5>
                                            <ul>
                                                <li>
                                                    <p>
                                                        <b>
                                                            Pipeline di CI/CD
                                                            Personalizzate
                                                        </b>
                                                        : Una pipeline di CI/CD
                                                        è una serie di passaggi
                                                        automatizzati che il
                                                        codice sorgente
                                                        attraversa,
                                                        dall'integrazione alla
                                                        distribuzione. Ogni
                                                        passo può includere test
                                                        automatizzati, controlli
                                                        di qualità del codice e
                                                        attività di
                                                        distribuzione.
                                                    </p>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h5>
                                                Infrastruttura come Codice (IaC)
                                            </h5>
                                            <ul>
                                                <li>
                                                    <p>
                                                        <b>
                                                            Gestione
                                                            Automatizzata
                                                            dell'Infrastruttura
                                                        </b>
                                                        : L'Infrastruttura come
                                                        Codice (IaC) implica la
                                                        definizione
                                                        dell'infrastruttura
                                                        (server, database, reti)
                                                        utilizzando codice.
                                                        Strumenti come Terraform
                                                        o Ansible consentono la
                                                        creazione automatizzata
                                                        di ambienti di sviluppo,
                                                        test e produzione.
                                                    </p>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h5>Test Continuo</h5>
                                            <ul>
                                                <li>
                                                    <p>
                                                        <b>
                                                            Automatizzazione dei
                                                            Test
                                                        </b>
                                                        : Il test continuo
                                                        implica l'esecuzione
                                                        automatica di vari tipi
                                                        di test, tra cui test
                                                        unitari, di integrazione
                                                        e di accettazione, per
                                                        garantire che il
                                                        software funzioni
                                                        correttamente. Ogni
                                                        modifica al codice viene
                                                        testata automaticamente
                                                        per individuare
                                                        eventuali problemi.
                                                    </p>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h5>Monitoraggio e Log</h5>
                                            <ul>
                                                <li>
                                                    <p>
                                                        <b>
                                                            Monitoraggio
                                                            Continuo
                                                        </b>
                                                        : L'implementazione di
                                                        un robusto sistema di
                                                        monitoraggio e
                                                        registrazione è
                                                        essenziale. Questo
                                                        consente di rilevare e
                                                        risolvere
                                                        tempestivamente problemi
                                                        nella produzione,
                                                        garantendo che il
                                                        software sia sempre
                                                        funzionante e
                                                        affidabile.
                                                    </p>
                                                </li>
                                            </ul>
                                        </li>
                                    </ol>

                                    <h3>Strumenti Comuni</h3>
                                    <p>
                                        Ci sono molti strumenti disponibili per
                                        supportare CI/CD, tra cui:
                                    </p>
                                    <ul>
                                        <li>
                                            <p>
                                                <b>Jenkins</b>: Jenkins è un
                                                server di automazione
                                                open-source ampiamente
                                                utilizzato per la realizzazione
                                                di pipeline di CI/CD
                                                personalizzate. È altamente
                                                configurabile e può essere
                                                integrato con numerosi
                                                strumenti.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <b>Travis CI</b>: Travis CI è un
                                                servizio di CI/CD basato su
                                                cloud che si integra facilmente
                                                con repository su GitHub. Offre
                                                una configurazione semplice e un
                                                ambiente di test controllato.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <b>GitLab CI/CD</b>: GitLab
                                                offre funzionalità di CI/CD
                                                direttamente nell'ambiente di
                                                GitLab. Questo permette una
                                                gestione centralizzata del
                                                codice e dei processi CI/CD.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <b>CircleCI</b>: CircleCI è un
                                                servizio di CI/CD basato su
                                                cloud con una pipeline
                                                personalizzabile e flessibile. È
                                                noto per la sua facilità d'uso e
                                                la sua integrazione con
                                                repository popolari.
                                            </p>
                                        </li>
                                    </ul>
                                    <p>
                                        Sperando di aver fatto un po’ di luce in
                                        questo mare di complessità, ti auguriamo
                                        come sempre Buon coding, e se serve una
                                        mano nel tuo progetto puoi sempre
                                        contare su di noi.
                                    </p>
                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                </div>

                                <div
                                    style={{ marginTop: 30 }}
                                    className="startp-post-navigation"
                                >
                                    {Blog.articles
                                        .filter((a) => a.id != articleData.id)
                                        .slice(0, 2)
                                        .map((article) => {
                                            return (
                                                <div className="prev-link-wrapper">
                                                    <div className="info-prev-link-wrapper">
                                                        <Link
                                                            to={
                                                                "/" +
                                                                article.page
                                                            }
                                                        >
                                                            <span className="image-prev">
                                                                <img
                                                                    src={
                                                                        require("../../assets/images" +
                                                                            article.img)
                                                                            .default
                                                                    }
                                                                    alt="image"
                                                                />
                                                                <span className="post-nav-title">
                                                                    VAI
                                                                </span>
                                                            </span>
                                                            <span className="prev-link-info-wrapper">
                                                                <span className="prev-title">
                                                                    {
                                                                        article.title
                                                                    }
                                                                </span>
                                                                <span className="meta-wrapper">
                                                                    <span className="date-post">
                                                                        {
                                                                            article.data
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <BlogSidebar id={articleData.id} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Layout>
    );
};

export default BlogDetails;
